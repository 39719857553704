<template>
  <div style="padding: 50px;">
    <div id="wrapper" class="clearfix">
      <section class="training-header">
        <div class="content-wrap">
          <div class="container clearfix" style="text-align: center; ">
            <div>
              <h2 style="color: white">{{ name }}</h2>
            </div>
            <span style="color: white"
              >Organisé par: {{ creator.contact.name }}
            </span>
            <span style="color: white" v-if="creator.contact.qualiopi === 1">
              - certifié qualiopi</span
            >
          </div>
        </div>
      </section>
      <section style="padding-bottom: 50px">
        <div class="content-wrap">
          <div
            class="container clearfix"
            style="text-align: center; justify-content: center; align-items: center; font-size: 1.5rem"
          >
            <div>
              <div class=" heading-block bottommargin-lg center  ">
                <h3>Description</h3>
                <span> {{ description }} </span>
              </div>
            </div>
            <div>
              <v-icon>
                mdi-calendar-range
              </v-icon>
              <strong>Date: </strong> <span> {{ dateStart }}</span>
              <span> - </span>
              <span>{{ dateEnd }}</span>
            </div>
            <div>
              <v-icon> mdi-google-maps</v-icon> <b>Adresse: </b> {{ address }}
              {{ city }} -

              {{ dep[department - 1].name }} ({{ dep[department - 1].code }})
            </div>
            <br />
            <div>
              <span>
                <v-icon>mdi-domain</v-icon> <strong>Domaine:</strong>
                {{ domainsListe[competencie - 1].name }}
              </span>
            </div>
            <br />
            <div>
              <span>
                Nombre de places max:
                {{ nb_place_max }}
              </span>
              |
              <span>
                Nombre de places minimum:
                {{ nb_place_min }}
              </span>
              |
              <span>
                Nombre de places restantes:
                {{ nb_place_rest }}
              </span>
            </div>
            <br />
            <div>
              <span>
                <v-icon>mdi-currency-eur</v-icon>
                <strong>Tarif intra:</strong> {{ tarif1 }} -
                <strong> Tarif inter :</strong> {{ tarif2 }}
              </span>
            </div>
          </div>
        </div>
        <br />
        <div>
          <v-row>
            <v-col cols="12" md="4" sm="12"> </v-col>
            <v-col style="" cols="12" md="4" sm="12">
              <v-text-field
                label="Réserver ... Place(s)"
                v-model="place_res"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="12"> </v-col>
            <v-col cols="12" md="4" sm="12"> </v-col>
            <v-col style="" cols="12" md="4" sm="12">
              <v-btn
                block
                color="#00897f"
                dark
                @click="
                  place_res && place_res > 0 ? register() : (activeSnack = true)
                "
              >
                Réserver
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" sm="12"> </v-col>
          </v-row>
        </div>
        <v-snackbar v-model="activeSnack" :timeout="timeout" top color="red">
          Merci de rentrer le nombre de place à reserver
          <template v-slot:action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click="activeSnack = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </section>
      <!-- #content end -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as dayjs from "dayjs";

export default {
  data: () => ({
    loadingData: false,
    avatarUrl: "https://picsum.photos/id/11/500/300",
    competencie: "",
    name: "",
    description: "",
    nb_place_max: 0,
    nb_place_min: 0,
    nb_place_rest: 0,
    creator: {},
    department: 0,
    formative: {},
    start: "",
    end: "",
    active: 0,
    dialogOpen: false,
    selectedEvent: {},
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    place_res: "",
    activeSnack: false,
    timeout: 2000,
    tarif1: 0,
    tarif2: 0,
    address: "",
    city: "",
  }),
  mounted() {
    this.loadingData = true;

    this.fetchTrainingById(this.$route.params.id)
      .then((training) => {
        let data = training.data.data;
        this.name = data.name;
        this.competencie = data.domain.id;
        this.description = data.desc;
        this.nb_place_max = data.nb_place_max;
        this.nb_place_min = data.nb_place_min;
        this.nb_place_rest = data.nb_place_rest;
        this.creator = data.creator;

        this.department = data.department[0].id;
        this.formative = data.formative;
        this.start = data.start;
        this.end = data.end;
        this.active = data.active;
        this.loading = false;
        this.tarif1 = data.tarifs[0].tarif_1;
        this.tarif2 = data.tarifs[0].tarif_2;
        this.address = data.address;
        this.city = data.city;
      })
      .catch((e) => {
        console.error(e);
      });
  },
  methods: {
    register() {
      let data = {
        id: this.$route.params.id,
        body: {
          nb_place: this.place_res,
        },
      };
      this.registerEvent(data)
        .then((response) => {
          this.setSnack({ text: response.message });
        })
        .catch((error) => {
          this.setSnack({ text: error.message, color: "red" });
        });
    },
    ...mapActions("training", ["fetchTrainingById", "registerEvent"]),
    ...mapActions("event", ["patchEvent"]),
    ...mapActions("snackbar", ["setSnack"]),
  },
  computed: {
    ...mapGetters("meta_data", { domainsListe: "getDomainsListe" }),
    ...mapGetters("meta_data", { dep: "getDepartements" }),
    dateStart() {
      return dayjs(this.start, "YYYY--MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
    dateEnd() {
      return dayjs(this.end, "YYYY--MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style>
.profile-wrapper {
  padding-top: 34px;
  width: 90%;
  margin: auto;
}

.former-core-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.training-header {
  background: url("../../../assets/Search-SVG/training-header-background.svg")
    no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
}

.avatar-wrapper {
  position: absolute;
  right: 10%;
  top: 15%;
}

.profile-wrapper .former-core-info .former-profile-img {
  border-radius: 50%;
  object-fit: cover;
  width: 175px;
  height: 175px;
  position: absolute;
  margin: 5px;
}

.avatar-outline {
  height: 185px;
  width: 185px;
  border-radius: 50%;
  border-color: #11514c;
  background-color: white;
  border-style: solid;
  border-width: 10px;
}

.header-info {
  padding: 50px;
}

.header-info .former-name {
  color: white;
  margin: 0;
}

.header-info .rating-container {
  display: flex;
  align-items: center;
}

.former-description {
  display: flex;
  align-items: center;
  height: 280px;
  width: 44%;
}

.former-description > p {
  font-weight: bold;
  padding: 0 16px;
  color: #757575;
}

.former-advanced-info {
  margin-top: 34px;
}
</style>
